<div class="padding-box">
  <div class="panel-buttons">
    <h3 class="line-title">Movimiento Cliente</h3>
    <div class="spacer"></div>
    <button (click)="generateXml()" [disabled]="arrayDataSource.length == 0"
            style="background: #e60000; margin-right: 10px;" mat-fab>
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="form">
    <mat-expansion-panel style="margin-bottom: 15px; margin-top: 10px;" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filtros
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form" autocomplete="off">
        <div class="panel-row">
          <div class="panel-fields">
            <mat-form-field class="example-full-width">
              <mat-label>Filtrar por Nombre, cédula o correo</mat-label>
              <input type="text" matInput formControlName="client" [matAutocomplete]="auto" (input)="getClients()"
                     (focusout)="focusout($event)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of options" [value]="option">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
                <input readonly matStartDate formControlName="dateStart" placeholder="Fecha Inicio">
                <input readonly matEndDate formControlName="dateEnd" placeholder="Fecha Final">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div style="float: right;">
        <button style="margin-right: 10px;" (click)="clearValues();" mat-flat-button>Limpiar</button>
        <button style="color: white; background: #e60000; margin-right: 10px;" (click)="onChangeQueryParams()"
                [disabled]="!form.valid || (form.get('client').value && !form.get('client').value['id'])"
                mat-flat-button>
          aplicar
        </button>
      </div>
    </mat-expansion-panel>
  </ng-container>

  <mat-card style="overflow:auto; margin: 50px 0;" *ngFor="let data of arrayDataSource">
    <mat-card-subtitle> {{ data.title }} ( {{ data.iso }} )</mat-card-subtitle>
    <mat-card-subtitle> Saldo inicial: {{ tools.formatDisplay(data.balance, 3, 3, '.', ',') }}</mat-card-subtitle>
    <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="data.dataSources" matSortDisableClear>
      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef> Fecha</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.updated_at }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-center"><b>Total</b></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <mat-header-cell *matHeaderCellDef> Tienda</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descripcion }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Cliente</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="caja">
        <mat-header-cell *matHeaderCellDef> Caja</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.caja }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="nro_fact">
        <mat-header-cell *matHeaderCellDef> N° Factura</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.nro_fact }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="descriptrans">
        <mat-header-cell *matHeaderCellDef> Tipo</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descriptrans }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="proceso">
        <mat-header-cell *matHeaderCellDef> Proceso</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.proceso }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="debito">
        <mat-header-cell *matHeaderCellDef> Debito</mat-header-cell>
        <mat-cell *matCellDef="let row" class="color-gray" [ngClass]="{'color-red': row.debito != 0}"> - {{ tools.formatDisplay(row.debito, 3, 3, '.', ',') }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="credito">
        <mat-header-cell *matHeaderCellDef> Credito</mat-header-cell>
        <mat-cell *matCellDef="let row" class="color-gray" [ngClass]="{'color-green': row.proceso === 'Aprobado','color-yellow': row.proceso === 'Diferido'}"> + {{ tools.formatDisplay(row.credito, 3, 3, '.', ',') }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="credito">
        <mat-header-cell *matHeaderCellDef> Credito</mat-header-cell>
        <mat-cell *matCellDef="let row" class="color-gray" [ngClass]="{'color-green': row.credito != 0}"> + {{ tools.formatDisplay(row.credito, 3, 3, '.', ',') }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container> -->

      <ng-container matColumnDef="saldo">
        <mat-header-cell *matHeaderCellDef> Saldo</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ tools.formatDisplay(row.balance, 3, 3, '.', ',') }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-center">
          <b>{{ tools.formatDisplay(data.acum, 3, 3, '.', ',') }}</b></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>
  </mat-card>


  <div *ngIf="loading"
       style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner></mat-spinner>
  </div>
</div>
